import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Navbar from './components/Navbar';
import Navbar_NEXUS from './components/Navbar_NEXUS';
import Table from './components/Table';
import Chart from './components/Chart';
import './App.css';

function App() {
  const [data, setData] = useState(null);
  const [highlightedFeaturesIndexes, setHighlightedFeaturesIndexes] = useState([]);

  useEffect(() => {
    axios.get('https://vizrec.dei.uc.pt/api/')
      .then(response => {
        setData(response.data.message);
      })
      .catch(error => {
        console.error('There was an error fetching the data!', error);
      });
  }, []);

  return (
    <>
      <div className='Navbar'>
        <Navbar />
      </div>
      <div className='grid-container'>
        <div className='Chart'>
          <Chart onHover={{setHighlightedFeaturesIndexes}} />
        </div>
        <Table 
          highlightedFeaturesIndexes={highlightedFeaturesIndexes}
        />
      </div>
    </>
   
  );
}

export default App;
