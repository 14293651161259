import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaCog } from 'react-icons/fa';
import { FaRegFolderOpen } from "react-icons/fa";
import { MdOutlineAnalytics } from "react-icons/md";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Navbar.css';
import axios from 'axios';
import { LuAlertTriangle } from "react-icons/lu";
import PopUp from './PopUp';



const AppNavbar = ({ onFileUpload }) => {
  const navigate = useNavigate();
  const [datasetName, setDatasetName] = useState('');
  const [file, setFile] = useState(null);
  const [removedRows, setRemovedRows] = useState("");
  const [removedCols, setRemovedCols] = useState("");

  const [analyseVisible, setAnalyseVisible] = useState(false);
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);


  const openPopUp = () => {
    setIsPopUpVisible(true);
  };

  const closePopUp = () => {
    setIsPopUpVisible(false);
  };



  const handleChange = (event) => {  

      const file = event.target.files[0];
    
      if (!file) {
        setAnalyseVisible(false); 
      }
      else {
        setFile(file);
        setAnalyseVisible(true);
      }

  };

  const handleSubmit = async (event) => {

    if (analyseVisible) {

      event.preventDefault();
      const formData = new FormData();
      formData.append('file', file);
      
      try {
          const response = await axios.post('https://vizrec.dei.uc.pt/api/receive_dataset', formData, {
              headers: {
                  'Content-Type': 'multipart/form-data',
              },
          })
          window.location.reload(false);

          if (response.data.error) {
              alert(response.data.error);
          } else {
              alert(response.data.message);
          }
      } catch (error) {
          console.error('Error uploading file:', error);
      }
    } 
};

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://vizrec.dei.uc.pt/api/get_table_name'); 
      setDatasetName(response.data.dataset_name);
      const response2 = await axios.get('https://vizrec.dei.uc.pt/api/get_rows_columns_removed'); 
      setRemovedCols(response2.data.resultData.removed_columns.removed_columns);
      setRemovedRows(response2.data.resultData.removed_rows.removed_rows);
      /*setLoading(false);*/
    } catch (error) {
      console.error('Error fetching data:', error);
      /*setError(error);
      setLoading(false);*/
    }
  };

  return (
    <div className='navbar-container'>
      <div className='dataset-container'>
        <form className='buttons_dataset' onSubmit={handleSubmit}>
            <input 
                type="file" 
                id="fileInput"
                style={{ display: 'none' }} 
                onChange={handleChange} 
            />
            <label 
                htmlFor="fileInput" 
                className="btn btn-primary"
            >
              <FaRegFolderOpen style={{marginBottom: "3.5px", marginRight: "5px" }} />
              Choose Dataset
            </label>


            {(analyseVisible) ? 
              <div> 
                <button type="submit" className="btn btn-primary ">
                  <MdOutlineAnalytics style={{marginBottom: "3.5px", marginRight: "4px" }}/>
                  Analyse
                </button>
              </div> : 
              <div> 
                <button type='button' className="btn btn-primary analyse-button-before">
                  <MdOutlineAnalytics style={{marginBottom: "3.5px", marginRight: "4px"}}/>
                  Analyse
                </button>
              </div>
             }
            
            <div className='dataset-name'>
              <p>
              Dataset being visualized: {datasetName}
              </p>
              <div className='alert-section'>
                {(!(String(removedCols).valueOf() == new String("").valueOf())) || (!(String(removedRows).valueOf() == new String("").valueOf())) ? 
                  <div> 
                    <button type="button" className="btn btn-primary alertButton" onClick={openPopUp}>
                    <LuAlertTriangle className="alert-icon" size={20}/>
                  </button>
                    {isPopUpVisible && <PopUp closePopUp={closePopUp} />} </div> : ""}
              </div>
            </div>


        </form>
        {file && <p className="new-dataset">Dataset to be uploaded: {file.name}</p>}
      </div>
      <Button className='settings-button' onClick={() => navigate('/settings')} >
        <FaCog style={{marginBottom: "3px", marginRight: "4px" }}/>
        Settings
      </Button>

    </div>
  );
};

export default AppNavbar;
