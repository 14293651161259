import React, { useEffect, useState } from 'react';
import './Table.css';
import Loader from "./Loader";


const TableComponent = ({ highlightedFeaturesIndexes }) => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {

    fetch("https://vizrec.dei.uc.pt/api/get_table", {
      method: "GET",
      dataType: 'json',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.json())
      .then((data) => {
        console.log(data);
        setTableData(data.resultData.table_data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
    
  };


  const renderTableHeaders = () => {
    if (tableData.length === 0) return null;

    // Assuming the first item in tableData has all necessary keys for columns
    const headers = Object.keys(tableData[0]);
    return (
      <tr>
        {headers.map((header, index) => (
          <th
            key={index}
            style={{
              backgroundColor: highlightedFeaturesIndexes.includes(index) ? '#fac858' : 'white'
            }}
          >
            {header}
          </th>
        ))}
      </tr>
    );
  };

  const renderTableRows = () => {
    return tableData.map((row, index) => (
      <tr key={index}>
        {Object.values(row).map((value, idx) => (
          <td key={idx}
            style={{
              backgroundColor: highlightedFeaturesIndexes.includes(idx) ? '#ffe5ab' : 'white'
            }}
            >
            {value}
          </td>
        ))}
      </tr>
    ));
  };

  return (
    <div className='table-container'>
      {loading ? <div className='loading-table'><Loader /> <p>  Loading data table...</p></div> :  
      <table className="table table-striped">
        <thead>
          {renderTableHeaders()}
        </thead>
        <tbody>
          {renderTableRows()}
        </tbody>
      </table>
      }
    </div>
  );
};

export default TableComponent;
