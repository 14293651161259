import React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { FaCog } from 'react-icons/fa';
import { RiArrowGoBackFill } from "react-icons/ri";
import 'bootstrap/dist/css/bootstrap.min.css';
import './Navbar_Settings.css';

const Navbar_Settings = () => {
  const navigate = useNavigate();

  return (
    <div className='navbar-container'>
      <div className='title-container'>
        <FaCog/> Settings
      </div>
      <Button className='go_back-button' onClick={() => navigate('/')} >
        <RiArrowGoBackFill style={{marginBottom: "3px", marginRight: "4px" }} />
        Go back
      </Button>

    </div>
  );
};

export default Navbar_Settings;
