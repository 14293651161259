import React from 'react';
import './Checkbox.css';

const Checkbox = ({ label, isChecked, onCheckboxChange }) => {
  return (
    <div className='checkbox'>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onCheckboxChange}
        id={label}
      />
      {label}
    </div>
  );
};

export default Checkbox;