import React, { useEffect, useState } from 'react';
import './PopUp.css'; 
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { IoAlertCircleOutline, IoClose } from "react-icons/io5";


const PopUp = ({ closePopUp }) => {

    const [removedRows, setRemovedRows] = useState("");
    const [removedCols, setRemovedCols] = useState("");
    const [justificationRows, setJustificationRows] = useState("");
    const [justificationCols, setJustificationCols] = useState("");

    useEffect(() => {
        fetchData();
      }, []);
    
    const fetchData = async () => {
        try {
            const response = await axios.get('https://vizrec.dei.uc.pt/api/get_rows_columns_removed'); 
            setRemovedCols(response.data.resultData.removed_columns.removed_columns);
            setJustificationCols(response.data.resultData.removed_columns.justification);
            setRemovedRows(response.data.resultData.removed_rows.removed_rows);
            setJustificationRows(response.data.resultData.removed_rows.justification);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    return (
        <div className="popup-overlay">
            <div className="popup-content">
                <div className='close-but-container'>
                    
                    <button className="btn btn-primary pop-up-btn" onClick={closePopUp}><IoClose className='close_pop_icon'/></button>
                </div>
                <div className='pop-up-cont '>
                    {!(String(removedCols).valueOf() == new String("").valueOf()) ? <div className='cols-removed'> <IoAlertCircleOutline className='inside-alert'></IoAlertCircleOutline> Columns {removedCols} were not taken into account for the analysis because {justificationCols} </div> : ""}
                    {!(String(removedRows).valueOf() == new String("").valueOf()) ? <div className='rows-removed'>  <IoAlertCircleOutline className='inside-alert'></IoAlertCircleOutline> Rows {removedRows} were not taken into account for the analysis because {justificationRows} </div> : ""}
                </div>
            </div>
        </div>
    );
    };

export default PopUp;
