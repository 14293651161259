import React, { useEffect, useState } from 'react';
import './Settings.css';
import Checkbox from './components/Checkbox';
import Navbar_Settings from './components/Navbar_Settings';
import { FaSave } from "react-icons/fa";
import { useNavigate } from 'react-router-dom';

function Settings() {

  const navigate = useNavigate();

  const [tasks, setTasks] = useState([]);
  const [actualTask, setActualTask] = useState("");
  const [selectedCheckbox, setSelectedCheckbox] = useState(null);

  useEffect(() => {
    
    fetch("https://vizrec.dei.uc.pt/api/get_tasks", {
      method: "GET",
      dataType: 'json',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((data) => {
          setTasks(data.tasks);
          setActualTask(data.actual_task);
      })
      .catch((error) => console.log(error));

  }, []);

  useEffect(() => {
    if (actualTask) {
        setSelectedCheckbox(actualTask);
    }
  }, [actualTask]);


  const handleCheckboxChange = (event) => {
    const { id } = event.target;
    setSelectedCheckbox(id);
  };

  const handleSubmit = async ()  => {

    if (actualTask) {

      fetch("https://vizrec.dei.uc.pt/api/receive_actual_task", {
          method: "POST",
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
          },
          body: JSON.stringify({ actualTask: selectedCheckbox })
      })
      .then(response => response.json())
      .then(data => {
          console.log('Response from backend when saving actual task:', data.message);
      })
      .then(navigate('/'))
      .catch((error) => console.log(error));
  }
};

    return (
      <>
        <div className='Navbar_Settings'>
          <Navbar_Settings />
        </div>

        <div className='task-options'>
          Attribute selection method:
          {tasks.map((item) => (
            <Checkbox
              key={item}
              label={item}
              isChecked={selectedCheckbox === item}
              onCheckboxChange={handleCheckboxChange}
            />
          ))}
        </div>

        <div className='save-button'>
          <button className="btn btn-primary " onClick={handleSubmit}>
            <FaSave  style={{marginBottom: "2px", marginRight: "4px" }}/>
            Save
          </button>
        </div>

      </>
    
    );
  }

export default Settings;
