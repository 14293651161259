import React, { useEffect, useRef, useState } from 'react';
import './Chart.css'; 
import Loader from "./Loader";

const ChartComponent = ({ onHover }) => {
    const chartContainerRefs = useRef([]);
    const [chartsHtml, setChartsHtml] = useState([]);
    const [chartsFeaturesIndexes, setChartsFeaturesIndexes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [clickedChartIndex, setClickedChartIndex] = useState(null);

    useEffect(() => {
        loadEChartsScript()
            .then(() => fetchData())
            .catch((error) => console.error('Error loading ECharts script:', error));
    }, []);

    const loadEChartsScript = () => {
        return new Promise((resolve, reject) => {
            const script = document.createElement('script');
            script.src = 'https://assets.pyecharts.org/assets/v5/echarts.min.js';
            script.onload = () => resolve();
            script.onerror = () => reject(new Error('Failed to load ECharts script'));
            document.head.appendChild(script);
        });
    };

    const fetchData = async () => {
        fetch("https://vizrec.dei.uc.pt/api/get_chart", {
            method: "GET",
            dataType: 'json',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          })
            .then((response) => response.json())
            .then((data) => {
                setChartsHtml(data.charts);
                setChartsFeaturesIndexes(data.features_index);
                setLoading(false);
            })
            .catch((error) => console.log(error));
    };

    useEffect(() => {
        if (chartsHtml.length > 0) {
            chartsHtml.forEach((chartHtml, index) => renderChart(chartHtml, index));
        }
    }, [chartsHtml]);

    const renderChart = (chartHtml, index) => {
        if (chartContainerRefs.current[index]) {
            chartContainerRefs.current[index].innerHTML = chartHtml;

            // Extract and evaluate script tags
            const scriptElements = chartContainerRefs.current[index].getElementsByTagName('script');
            for (let i = 0; i < scriptElements.length; i++) {
                const script = document.createElement('script');
                script.text = scriptElements[i].text;
                script.async = false;
                document.body.appendChild(script).parentNode.removeChild(script);
            }
        }
    };

    const deal_UnClick = () => {
        onHover.setHighlightedFeaturesIndexes([]);
        setClickedChartIndex(null);
    }

    const deal_Click = (index) => {
        onHover.setHighlightedFeaturesIndexes(chartsFeaturesIndexes[index]);
        setClickedChartIndex(index);
    }

    return (
        <div className="charts-container">
            {loading ? <div className='loading-charts'><Loader /> <p>Loading charts...</p></div> :
            chartsHtml.map((chartHtml, index) => (
                <div
                    key={index}
                    className={`chart-box ${clickedChartIndex === index ? 'clicked' : (clickedChartIndex !== null ? 'unselected' : '')}`}
                    ref={(el) => (chartContainerRefs.current[index] = el)}
                    onMouseOver={() => {(clickedChartIndex === null) ? onHover.setHighlightedFeaturesIndexes(chartsFeaturesIndexes[index]) : console.log()}}
                    onMouseOut={() => {(clickedChartIndex  === null) ? onHover.setHighlightedFeaturesIndexes([]) : console.log()}}
                    onClick={() => {clickedChartIndex === index ? deal_UnClick() : deal_Click(index)}}
                />
            ))}
        </div>
    );
};

export default ChartComponent;
